import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import { findNumbers } from "libphonenumber-js";

import PhotoMessage from '../PhotoMessage/PhotoMessage';
import VoiceMessage from '../VoiceMessage/VoiceMessage';
import SystemMessage from '../SystemMessage/SystemMessage';
import ManagementMessage from "../ManagementMessage/ManagementMessage";
import VoipInteractiveMessages from "../VoipInteractiveMessages/VoipInteractiveMessages";
import ProgrammableMessage from "../ProgrammableMessage/ProgrammableMessage";
import { NewMykoinInteractiveMessages } from "../NewMykoinInteractiveMessages/NewMykoinInteractiveMessages";
import { IoMdDoneAll, IoMdCheckmark, IoMdTime } from 'react-icons/io';
import classNames from 'classnames';
import { joinToVideoChat, rejectCall } from "../../../utils/redux/actions/chatActions/actions/chat-menu";
import {
	setTokenTypeForVoip,
	confirmSendMk,
	refuseSendMk,
	toggleCopyCallBottomSheet, confirmTransferInApp,
} from "../../../utils/redux/actions/chatActions/chatChangeState";
import CustomButton from "../../ui-elements/CustomButton/CustomButton";
import { highlight_phones } from "../../../utils/functions/message-object-creator";
import ContentEditable from "./ContentEditable";
import { fetchMethodsOfPayment, fetchXenditAndBalance } from "../../../utils/redux/actions/billingActions";
import { getPrimaryPayment } from "../../../utils/redux/actions/commonActions/commonChangeState";
import genderPatner from "../../../utils/functions/gender-partner";
import cashBack from "../../../assests/images/chat/cash-back.svg"
import expired from "../../../assests/images/chat/expired.svg"
import { setTransferInAppPayload } from "../../../utils/functions/local-storage";

export class MessageBox extends Component {
    componentWillReceiveProps(nextProps) {
        if (nextProps.focus !== this.props.focus && nextProps.focus === true) {
            if (this.refs[ 'message' ]) {
                this.refs[ 'message' ].scrollIntoView({
                    block: "center",
                    behavior: 'smooth'
                })

                this.props.onMessageFocused(nextProps);
            }
        }
    }

	handleClick(e) {
		e.persist();
		this.props.dispatch(toggleCopyCallBottomSheet(e.target.innerHTML));
	}

	onOpenGCash = () => {
		const url = "intent://gcash.splashscreen/#Intent;scheme=https;package=com.globe.gcash.android;end";
		window.location.replace(url);
	}

	onConfirmTransferInApp = () => {
		this.props.dispatch(fetchXenditAndBalance())
			.then((res) => {
				if (res.data.data.getXenditPaymentMethods.totalItems > 0) {
					const primaryPayment = res.data.data.getXenditPaymentMethods.items.filter((payment) => payment.isPrimary === true);
					this.props.dispatch(getPrimaryPayment(primaryPayment));
				}
			})
		setTransferInAppPayload({
			amount: this.props.extraInfo.amount,
			transferFee: this.props.extraInfo.transferFee,
			referenceId: this.props.referenceId,
			partnerName: `${this.props.partner.givenName} ${this.props.partner.lastName}`,
			currencySign: this.props.client.currency.signText,
		})
		this.props.dispatch(confirmTransferInApp({
			amount: this.props.extraInfo.amount,
			transferFee: this.props.extraInfo.transferFee,
			referenceId: this.props.referenceId,
			partnerName: `${this.props.partner.givenName} ${this.props.partner.lastName}`,
			currencySign: this.props.client.currency.signText,
		}))
	}

    render() {
        const dateText = this.props.isDateShow ? this.props.date && !isNaN(this.props.date) && (this.props.dateString) : '';

		let numbersFound = [];
		try {
			numbersFound = findNumbers(this.props.text, "PH", { v2: true });
		} catch (error) {
			console.error(error);
		}
		highlight_phones(this.props.text, numbersFound);

        return (
			<div
				ref="message"
				id={`message-box-${this.props.index}`}
				className={classNames(
					"rce-container-mbox",
					this.props.className,
					this.props.type.toLowerCase() === "video_chat_token" ||
						this.props.type.toLowerCase() === "voice_chat_token" ||
						(this.props.type.toLowerCase() === "interactive_request_mykoin" && this.props.authorType.toLowerCase() === "partner")
						? "interactive-box-wrapper"
						: this.props.authorType.toLowerCase(),
					this.props.type,
				)}
				onClick={this.props.onClick}
			>
				{this.props.authorType.toLowerCase() === "system" ? (
					<SystemMessage text={this.props.text} date={this.props.copiableDate ? undefined : dateText} />
				) : null}
				{this.props.authorType.toLowerCase() === "management" || this.props.authorType.toLowerCase() === "enterprise" ? (
					this.props.type === 'image' ? (
							<>
								<span className="timezone">{this.props.copiableDate ? undefined : dateText}</span>
								<PhotoMessage
									onOpen={this.props.onOpen}
									onDownload={this.props.onDownload}
									onLoad={this.props.onLoad}
									onPhotoError={this.props.onPhotoError}
									data={this.props.data}
									text={this.props.text}
									authorType={this.props.authorType.toLowerCase()}
								/>
							</>
						) : (
							<ManagementMessage text={this.props.text} date={this.props.copiableDate ? undefined : dateText} />
						)
				) : null}
				{this.props.authorType === "PROGRAMMABLE_MESSAGE" && this.props.type === "text" ? (
					<ProgrammableMessage text={this.props.text} date={this.props.copiableDate ? undefined : dateText} />
				) : this.props.authorType === "PROGRAMMABLE_MESSAGE" && this.props.type === "image" ? (
					<>
						<span className="timezone">{this.props.copiableDate ? undefined : dateText}</span>
						<PhotoMessage
							onOpen={this.props.onOpen}
							onDownload={this.props.onDownload}
							onLoad={this.props.onLoad}
							onPhotoError={this.props.onPhotoError}
							data={this.props.data}
							text={this.props.text}
							authorType={this.props.authorType.toLowerCase()}
						/>
					</>
				) : null}

				{this.props.authorType.toLowerCase() === "client" || this.props.authorType.toLowerCase() === "partner" ? (
					<Fragment>
						{this.props.state !== "CANCELLED" &&
							<span className="timezone">{this.props.copiableDate ? undefined : dateText}</span>}
						<div className="rce-mbox">
							<div className="rce-mbox-body" onContextMenu={this.props.onContextMenu}>
								{this.props.type === "video_chat_token" || this.props.type === "voice_chat_token" ? (
									<VoipInteractiveMessages
										date={this.props.copiableDate ? undefined : dateText}
										authorType={this.props.authorType.toLowerCase()}
										title={`Incoming ${this.props.type === "video_chat_token" ? "Video" : "Voice"} Call`}
										chatType={this.props.type}
										subTitle={this.props.text}
										partnerName={this.props.partner.givenName}
										state={this.props.state}
										secondaryButton={
											<CustomButton
												type="button"
												title="Reject"
												outline
												variant="outlined"
												onClick={() =>
													this.props.dispatch(
														rejectCall(this.props.type.toUpperCase(), this.props.referenceId, this.props.jobId),
													)
												}
											/>
										}
										primaryButton={
											<CustomButton
												onClick={() => {
													this.props.dispatch(
														joinToVideoChat(
															this.props.jobId,
															this.props.tokenPayload,
															this.props.type.toUpperCase(),
															this.props.referenceId,
														),
													);
													this.props.dispatch(setTokenTypeForVoip(this.props.type.toUpperCase()));
												}}
												type="button"
												title="Accept"
											/>
										}
									/>
								) : null}
								{this.props.type === "interactive_request_mykoin" ? (
									<>
										{this.props.state === "ACCEPTED" ? (
											<div className="interactive_request_result">
												<div className="title">{`${this.props.client.currency.signText}${
													this.props.extraInfo.amount
												} requested by ${
													this.props.jobs.job.partner.title
														? this.props.jobs.job.partner.title
														: genderPatner(this.props.jobs.job.partner)
												}`}</div>
												<img src={cashBack} />
												<div className="accepted">Successfully transferred</div>
											</div>
										) : this.props.state === "PENDING" && this.props.isChatHistory ? (
											<div className="interactive_request_result">
												<div className="title">{`${this.props.client.currency.signText}${
													this.props.extraInfo.amount
												} requested by ${
													this.props.jobs.job.partner.title
														? this.props.jobs.job.partner.title
														: genderPatner(this.props.jobs.job.partner)
												}`}</div>
												<img src={expired} />
												<div className="expired">Expired</div>
											</div>
										) : (
											this.props.state !== "CANCELLED" && <NewMykoinInteractiveMessages
												authorType={this.props.authorType.toLowerCase()}
												partnerName={this.props.partner.givenName}
												mykoinAmount={this.props.extraInfo.amount}
												mykoinSign={this.props.client.currency.signText}
												gCashNumber={this.props.extraInfo.gCashNumber}
												partner={this.props.jobs.job.partner}
												subTitle={
													this.props.authorType.toLowerCase() === "partner"
														? "Would you like to accept this request?"
														: this.props.text
												}
												secondaryButton={
													<CustomButton
														onClick={this.onConfirmTransferInApp}
														classList="reject-send-mk"
														type="button"
														title="Pay In-App"
														variant="outlined"
														outline
														disabled={this.props.state !== "PENDING" || this.props.jobs.job.state === "Completed"}
													/>
												}
												primaryButton={
													<CustomButton
														classList="accept-send-mk"
														onClick={this.onOpenGCash}
														type="button"
														title="Open GCash"
														disabled={this.props.state !== "PENDING" || this.props.jobs.job.state === "Completed"}
													/>
												}
											/>
										)}
									</>
								) : null}
								{this.props.type === "text" ? (
									numbersFound.length > 0 ? (
										<ContentEditable
											html={highlight_phones(this.props.text, numbersFound)}
											readOnly
											className="rce-mbox-text include-number"
											handleClick={(e) => {
												this.handleClick(e);
											}}
										/>
									) : (
										<div className="rce-mbox-text">{this.props.text}</div>
									)
								) : null}
								{this.props.type === "image" && (
									<PhotoMessage
										onOpen={this.props.onOpen}
										onDownload={this.props.onDownload}
										onLoad={this.props.onLoad}
										onPhotoError={this.props.onPhotoError}
										data={this.props.data}
										text={this.props.text}
										authorType={this.props.authorType.toLowerCase()}
									/>
								)}
								{this.props.type === "audio" && (
									<div className={classNames({ "voice-partner": this.props.authorType === "PARTNER" })}>
										<VoiceMessage
											onOpen={this.props.onOpen}
											onDownload={this.props.onDownload}
											data={this.props.data}
											text={this.props.text}
										/>
									</div>
								)}

								{this.props.authorType === "CLIENT" &&
									this.props.type !== "video_chat_token" &&
									this.props.type !== "voice_chat_token" && (
										<div className="rce-mbox-time">
											<span className="rce-mbox-status">
												{this.props.status === "waiting" && <IoMdTime />}

												{this.props.status === "sent" && <IoMdCheckmark />}

												{this.props.status === "received" && <IoMdDoneAll />}
											</span>
										</div>
									)}
							</div>
						</div>
					</Fragment>
				) : null}
			</div>
		);
    }
}

MessageBox.defaultProps = {
    type: 'text',
    text: '',
    date: new Date(),
    data: {},
    onClick: null,
    onOpen: null,
    onDownload: null,
    onLoad: null,
    onPhotoError: null,
    status: null,
    dateString: null,
    // renderAddCmp: null,
    copiableDate: false,
    onContextMenu: null,
    focus: false,
    onMessageFocused: null,
};

const mapStateToProps = (state) => ({
	...state.chat,
	chat: state.chat,
	common: state.common,
	jobs: state.jobs,
	client: state.common.client,
	partner: state.jobs.job.partner
});
export default connect(mapStateToProps)(MessageBox);
